<template>
  <div class="test">
    <div class="graph-container">
      <BarChart v-if="loaded" :graphData="graphData" :graphOptions="graphOptions"></BarChart>
    </div>
  </div>
</template>

<script>

import BarChart from '../components/BarChart.vue'
import EventService from "@/services/EventService.js"

export default {
    components: { BarChart },
    data() {
        return {
            loaded: false,
            drivers: null,
            graphData: null,
            xAxis: [],
            yAxis: [],
            barColor: [],
            graphOptions: {
              responsive: true,
              maintinAspectRatio: false
            }
        }
    },
    async created () {
     await EventService.getDrivers()
      .then(response => {
        this.drivers = response.data
        this.xAxis = this.setxAxis()
        this.yAxis = this.setyAxis()
        this.barColor = this.setBarColor()
        this.graphData = this.setGraphData()
        this.loaded=true
      })
      .catch(error => {
        console.log(error)
      })

      console.log(this.drivers)
      console.log(this.xAxis)
      console.log(this.yAxis)
      console.log(this.barColor)
      console.log(this.graphData)
    },
    methods: {
      // Populates x-axis with driver names
      setxAxis() {
        const driver  = []
        for(let i = 0; i < this.drivers.length; i++ ){
          driver.push(this.drivers[i].firstname)
        }
        return driver
      },

      // Populates y-axis with driver names
      setyAxis() {
         const category = []   
        for(let i = 0; i < this.drivers.length; i++ ){
          category.push(this.drivers[i].salary)
        }
        return category
      },

      // Dynamically set color of drivers bar depending on team
      setBarColor() {
        const color = []
        for(let i = 0; i < this.drivers.length; i++ ){
          switch(this.drivers[i].team){

            case('Alfa Romeo Racing Ferrari'):
              color.push('#900000')
              break

            case('Alphatauri Honda'):
              color.push('#2B4562')
              break

            case('Alpine Renault'):
              color.push('#0090FF')
              break

            case('Aston Martin Mercedes'):
              color.push('#006F62')
              break

            case('Ferrari'):
              color.push('#DC0000')
              break

            case('Haas Ferrari'):
              color.push('#FFFFFF')
              break

            case('Mclaren Mercedes'):
              color.push('#FF8700')
              break

            case('Mercedes'):
              color.push('#00D2BE')
              break

            case('Red Bull Racing Honda'):
              color.push('#0600EF')
              break

            case('Williams Mercedes'):
              color.push('#005AFF')
              break
          }
        }
        return color      
      },

      setGraphData() {
        const graphData = {
        labels: this.xAxis,
        datasets: [
            {
            label: 'Salary',
            data: this.yAxis,
            backgroundColor: this.barColor
            }
          ]
        }
        return graphData
      }
    }
}
</script>

<style>

.test{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graph-container{
  width: 45%;
  height: auto;
}
</style>