<template>
  <h1>404</h1>
  <h3> The {{ resource }} is not here</h3>
  <router-link :to="{ name: 'DriverList'}"> Home</router-link>
</template>

<script>
export default {
    props: {
        resource: {
            type: String,
            required: true,
            default: 'page'
        }
    }
}
</script>

<style>

</style>