<template>
  <router-link class="driver-card_link" :to="{name: 'DriverDetail', params: {id: driver.id}}">
    <div class="driver-card">
      <!-- Display Event Data -->
      <img class="driver-card_img" :src="require('../assets/' + driver.id + '.png')" >
      <h4>{{ `${driver.firstname}-${driver.lastname} `}}</h4>
      <h4>{{ `TEAM: ${driver.team}` }}</h4>
      <h3>{{ driver.nationality }}</h3>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "DriverCard",
  props: {
    driver: Object
  }
};
</script>


<style scoped>

.driver-card{
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid black;
  margin-bottom: 18px;
}

.driver-card:hover{
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 blue;
}

.driver-card_link {
  text-decoration: none;
  color: inherit;
}

.driver-card_img {
  width: 20%;
  height: auto;
}

</style>
