<template>
    <div><img alt="Vue logo" src="../assets/logo.png" /></div>
  <div class="drivers">
    <DriverCard v-for="driver in drivers" :key="driver.id" :driver="driver" />
  </div>
</template>

<script>
// @ is an alias to /src
import DriverCard from "@/components/DriverCard.vue";
import EventService from "@/services/EventService.js"

export default {
  name: "DriverList",
  components: {
    DriverCard,
  },
  data () {
    return {
      drivers: null
    }
  },
    created() {
      EventService.getDrivers()
      .then(response => {
        this.drivers = response.data
      })
      .catch(error => {
        console.log(error)
      })
    }
}
</script>

<style scoped>

.drivers{
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>