<template>
  <div v-if="driver">
      <img class="image-test" :src="test">
      <h3>{{ `${driver.firstname} ${driver.lastname}` }}</h3>
      <h4>{{ `${driver.nationality} -- ${driver.dob}` }}</h4>
      <h4>{{ `${driver.team}` }}</h4>
      <h4>{{ `${driver.salary}` }}</h4>
      <p>{{ `CurrentPoints: ${driver.currentPoints}` }}</p>
      <p>{{ `CareerPoints: ${driver.careerPoints}` }}</p>
      <p>{{ `CareerWins: ${driver.careerWins}` }}</p>
      <p>{{ `CareerPod: ${driver.careerPod}` }}</p>
      <p>{{ `CareerPos: ${driver.careerPos}` }}</p>
      <p>{{ `CurrentPos: ${driver.currentPos}` }}</p>
  </div>
</template>

<script>

import EventService from "@/services/EventService.js"

export default {
    props: ['id'],
    data() {
        return{
            driver: null
        }
    },
    // Fetch the data of a specific Driver
    //@params id
    created() {
        EventService.getDriver(this.id)
        .then(res => {
            this.driver = res.data
        })
        .catch(error => {
            console.log(error)
        })
    },
    computed: {
        // Use the ID of the driver to determine the img to use
        test : function() {
            return require("../assets/" + this.id + ".png")
        }
    }
}
</script>

<style>
.image-test{
    width: 20%;
    height: auto;
}
</style>