import axios from "axios"

const apiClient = axios.create({
    baseURL: 'https://my-json-server.typicode.com/msebersbergerjr/real-world-vue',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


export default {
    getDrivers() {
        return apiClient.get('/drivers')
    },
    getDriver(id) {
        return apiClient.get('/drivers/' + id)
        
    }
}