import { createRouter, createWebHistory } from "vue-router";
import DriverList from "../views/DriverList.vue";
import DriverDetail from "../views/DriverDetail.vue";
import Graph from "../views/Graph.vue"
import About from "../views/About.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "DriverList",
    component: DriverList,
  },
  {
    path: "/drivers/:id",
    name: "DriverDetail",
    props: true,
    component: DriverDetail
  },
  {
    path: "/graph",
    name: "Graph",
    component: Graph
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/:catchAll(.*)",
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/404/:resource',
    name: '404Resource',
    component: NotFound,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
