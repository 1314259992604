<script>

import { Bar } from 'vue3-chart-v2'

export default {
    name: 'BarChart',
    extends: Bar,
    props: {
        graphData: {
        type: Object,
        required: true
        },
        graphOptions: {
        type: Object,
        required: false
        },
    },
    mounted () {
        this.renderChart(this.graphData, this.graphOptions)
    }
}
</script>